import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const NAME = 'Empty';
const IMAGE = null;

class Empty extends React.Component {

    static get propTypes() {
        return {
            index: PropTypes.number.isRequired,
            name: PropTypes.string,
            image: PropTypes.string,
            position: PropTypes.array.isRequired,
            hor: PropTypes.number.isRequired,
            ver: PropTypes.number.isRequired,
        };
    }

    static get defaultProps() {
        return {
            name: NAME,
            image: IMAGE,
        };
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const key = (this.props.index + '-' + this.props.name).replace(/\s/g, '');
        const hor = config.grid_cell_size * this.props.hor;
        const ver = config.grid_cell_size * this.props.ver;
        const left = this.props.position[0] * config.grid_cell_size + 'px';
        const top = this.props.position[1] * config.grid_cell_size + 'px';
        return (
            <div key={key}
                className='element empty-cell no-padding'
                style={{top: top, left: left}}
                width={hor + 'px'}
                height={ver + 'px'}>
                 &nbsp;
            </div>
        );
    }

}

export default Empty;
