export default {
    1: { //ArmLeft
        taupe: '943',
        khaki: '943',
        hunter: '943',
        cognac: '1132',
        black: '1132',
        rust: '985',
        anthrachite: '985',
        petrol: '985',
    },
    2: { //ArmRight
        taupe: '943',
        khaki: '943',
        hunter: '943',
        cognac: '1132',
        black: '1132',
        rust: '985',
        anthrachite: '985',
        petrol: '985',
    },
    3: { //Element
        taupe: '817',
        khaki: '817',
        hunter: '817',
        cognac: '964',
        black: '964',
        rust: '851',
        anthrachite: '851',
        petrol: '851',
    },
    4: { //CornerLeft
        taupe: '964',
        khaki: '964',
        hunter: '964',
        cognac: '1397',
        black: '1397',
        rust: '1006',
        anthrachite: '1006',
        petrol: '1006',
    },
    5: { //CornerRight
        taupe: '964',
        khaki: '964',
        hunter: '964',
        cognac: '1397',
        black: '1397',
        rust: '1006',
        anthrachite: '1006',
        petrol: '1006',
    },
    6: { //ArmLongueLeft
        taupe: '1132',
        khaki: '1132',
        hunter: '1132',
        cognac: '1363',
        black: '1363',
        rust: '1187',
        anthrachite: '1187',
        petrol: '1187',
    },
    7: { //ArmLongueRight
        taupe: '1132',
        khaki: '1132',
        hunter: '1132',
        cognac: '1363',
        black: '1363',
        rust: '1187',
        anthrachite: '1187',
        petrol: '1187',
    },
    8: { //PoofElement
        taupe: '607',
        khaki: '607',
        hunter: '607',
        cognac: '712',
        black: '712',
        rust: '620',
        anthrachite: '620',
        petrol: '620',
    },
    9: { //Poof
        taupe: '607',
        khaki: '607',
        hunter: '607',
        cognac: '712',
        black: '712',
        rust: '620',
        anthrachite: '620',
        petrol: '620',
    },
};
