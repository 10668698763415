export default {
    '1,2': {img: '_combi_A.jpg'},                           // 1: ArmLeft - ArmRight
    '1,3,2': {img: '_combi_B.jpg'},                         // 2: ArmLeft - Element - ArmRight
    '1,3,3,2': {img: '_combi_C.jpg'},                       // 3: ArmLeft - Element - Element - ArmRight
    '1,3,3,5,13,11': {img: '_hoek2.jpg', notAcc: true},     // 4: ArmLeft - Element - Element - CornerRight - Element - ArmRight
    '1,3,3,5,13,17,11': {img: '_hoek2.jpg', notAcc: true},  // 5: ArmLeft - Element - Element - CornerRight - Element - PoofElement - ArmRight
    '1,3,3,5,17': {img: '_hoek2.jpg', notAcc: true},        // 6: ArmLeft - Element - Element - CornerRight - PoofElement
    '1,3,3,5,17,11': {img: '_hoek2.jpg', notAcc: true},     // 7: ArmLeft - Element - Element - CornerRight - PoofElement - ArmRight
    '1,3,3,5,17,13,11': {img: '_hoek2.jpg', notAcc: true},  // 8: ArmLeft - Element - Element - CornerRight - PoofElement - Element - ArmRight
    '1,3,5,13,11': {img: '_hoek2.jpg'},                     // 9: ArmLeft - Element - CornerRight - Element - ArmRight
    '1,3,5,13,17,11': {img: '_hoek2.jpg', notAcc: true},    // 10: ArmLeft - Element - CornerRight - Element - PoofElement - ArmRight
    '1,3,5,17': {img: '_combi_F.jpg'},                      // 11: ArmLeft - Element - CornerRight - PoofElement
    '1,3,5,17,11': {img: '_hoek2.jpg', notAcc: true},       // 12: ArmLeft - Element - CornerRight - PoofElement - ArmRight
    '1,3,5,17,13,11': {img: '_hoek2.jpg', notAcc: true},    // 13: ArmLeft - Element - CornerRight - PoofElement - Element - ArmRight
    '1,3,7': {img: '_combi_D_2.jpg'},                       // 14: ArmLeft - Element - ArmLongueRight
    '1,3,8': {img: '_combi_E.jpg', notAcc: true},           // 15: ArmLeft - Element - PoofElement
    '6,3,2': {img: '_combi_D.jpg'},                         // 16: ArmLongueLeft - Element - ArmRight
    '6,3,8': {img: '_combi_E.jpg'},                         // 17: ArmLongueLeft - Element - PoofElement
    '6,3,3,8': {img: '_combi_E.jpg', notAcc: true},         // 18: ArmLongueLeft - Element - Element - PoofElement
    '8,3,7': {img: '_combi_E_2.jpg'},                       // 19: PoofElement - Element - ArmLongueRight
    '8,3,3,7': {img: '_combi_E_2.jpg', notAcc: true},       // 20: PoofElement - Element - Element - ArmLongueRight
    '16,4,3,2': {img: '_hoek2.jpg', notAcc: true},          // 21: PoofElement - CornerLeft - Element - ArmRight
    'no': {img: 'hoek2.jpg', notAvailable: true},
    'poof': {img: '_poof.jpg', notAcc: true},
};
