export const partners = {
    partner1: {
        mailUrl: 'bliepbloep@email.com',
        logoUrl: 'logo.nl/logo',
        displayName: 'Partneria',
    },
    ptmd: {
        // mailUrl: 'bliepbloep@email.com',
        logoUrl: 'logo.nl/logo',
        displayName: 'PTMD®',
    },
};
