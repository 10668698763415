import React from 'react';
import '../css/noPartner.css';

const Nopartner = () => {
    return <div id='nopartner'>
        Partner niet gevonden / Geen partner meegegeven.
    </div>;
};

export default Nopartner;
