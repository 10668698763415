/* eslint-disable no-magic-numbers */
/* eslint-disable no-useless-escape */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
import React from 'react';
import Axios from 'axios';
import Lightbox from 'react-image-lightbox';
import {TextField} from '@material-ui/core';
import { motion } from 'framer-motion';
import config from '../config/config';

import swal from 'sweetalert';
import swali from '@sweetalert/with-react';
import ReactNotification, { store } from 'react-notifications-component';
// import CountryStoreLocator from '../../containers/CountryStoreLocator';

import 'react-image-lightbox/style.css';
import 'react-notifications-component/dist/theme.css';
import '../css/sofaConfig.css';

import colorMap from './colorMap';
import imageMap from './imageMap';
import pricingMap from './pricingMap';
import skuMap from './skuMap';

import Grid from './Grid';
import ArmLeft from './components/ArmLeft';
import ArmRight from './components/ArmRight';
import PoofElement from './components/PoofElement';
import ArmLongueLeft from './components/ArmLongueLeft';
import ArmLongueRight from './components/ArmLongueRight';
import ArmLongueRightAlt from './components/ArmLongueRightAlt';
import Element from './components/Element';
import CornerLeft from './components/CornerLeft';
import CornerRight from './components/CornerRight';
import Poof from './components/Poof';
import { useParams } from 'react-router';
import { partners } from './../partners';



const welcomeImages = [
    {title: 'Stap 1', image: 'Stap1Icon', subtext: 'Stel je opstelling samen'},
    {title: 'Stap 2', image: 'Stap2Icon', subtext: 'Kies het materiaal voor je bank'},
    {title: 'Stap 3', image: 'Stap3Icon', subtext: 'Kies je dichtstbijzijnde winkel'},
    {title: 'Stap 4', image: 'Stap4Icon', subtext: 'Kom proefzitten en krijg advies'},
];

const POSSIBLE_ELEMENTS = [
    { id: 1, name: 'ArmLeft', component: ArmLeft, rotation: 0, hor: 2, ver: 2, length: 117, width: 117, height: 84, sku: '111111' },
    { id: 2, name: 'ArmRight', component: ArmRight, rotation: 0, hor: 2, ver: 2, length: 117, width: 117, height: 84, sku: '111111' },
    { id: 3, name: 'Element', component: Element, rotation: 0, hor: 2, ver: 2, length: 92, width: 117, height: 84, sku: '111111' },
    { id: 4, name: 'CornerLeft', component: CornerLeft, rotation: 0, hor: 2, ver: 2, length: 117, width: 117, height: 84, sku: '111111' },
    { id: 5, name: 'CornerRight', component: CornerRight, rotation: 0, hor: 2, ver: 2, length: 117, width: 117, height: 84, sku: '111111' },
    { id: 6, name: 'ArmLongueLeft', component: ArmLongueLeft, rotation: 0, hor: 2, ver: 3, length: 173, width: 117, height: 84, sku: '111111' },
    { id: 7, name: 'ArmLongueRight', component: ArmLongueRight, rotation: 0, hor: 2, ver: 3, length: 173, width: 117, height: 84, sku: '111111' },
    { id: 8, name: 'PoofElement', component: PoofElement, rotation: 0, hor: 2, ver: 2, length: 96, width: 117, height: 42, sku: '111111' },
    { id: 9, name: 'Poof', component: Poof, rotation: 0, hor: 2, ver: 2, length: 96, width: 117, height: 42, sku: '111111' },
    { id: 10, name: 'ArmLeft', component: ArmLeft, rotation: 270, hor: 2, ver: 2, length: 117, width: 117, height: 84, parent: 1, sku: '111111' },
    { id: 11, name: 'ArmRight', component: ArmRight, rotation: 90, hor: 2, ver: 2, length: 117, width: 117, height: 84, parent: 2, sku: '111111' },
    { id: 12, name: 'Element', component: Element, rotation: 270, hor: 2, ver: 2, length: 117, width: 92, height: 84, parent: 3, sku: '111111' },
    { id: 13, name: 'Element', component: Element, rotation: 90, hor: 2, ver: 2, length: 117, width: 92, height: 84, parent: 3, sku: '111111' },
    { id: 14, name: 'ArmLongueLeft', component: ArmLongueLeft, rotation: 270, hor: 3, ver: 2, length: 117, width: 173, height: 84, parent: 6, sku: '111111' },
    { id: 15, name: 'ArmLongueRight', component: ArmLongueRightAlt, rotation: 90, hor: 3, ver: 2, length: 117, width: 173, height: 84, parent: 7, sku: '111111' },
    { id: 16, name: 'PoofElement', component: PoofElement, rotation: 270, hor: 2, ver: 2, length: 117, width: 96, height: 42, parent: 8, sku: '111111' },
    { id: 17, name: 'PoofElement', component: PoofElement, rotation: 90, hor: 2, ver: 2, length: 117, width: 96, height: 42, parent: 8, sku: '111111' },
];

const LEFT_CORNER_ELEMENTS = [1, 4, 6];
const RIGHT_CORNER_ELEMENTS = [2, 5, 7];
const TOP_ELEMENTS = [3, 8];
const LEFT_ELEMENTS = [10, 12, 14, 16];
const RIGHT_ELEMENTS = [11, 13, 15, 17];
const ADDITIONAL_ELEMENTS = [9];
const MAX_ELEMENTS = 50;

const POSSIBLE_CONFIGURATIONS = {
    1: [1, 2], // ArmLeft - ArmRight
    2: [1, 3, 2], // ArmLeft - Element - ArmRight
    3: [1, 3, 3, 2], // ArmLeft - Element - Element - ArmRight
    4: [1, 3, 3, 5, 13, 11], // ArmLeft - Element - Element - CornerRight - Element - ArmRight
    5: [1, 3, 3, 5, 13, 17, 11], // ArmLeft - Element - Element - CornerRight - Element - PoofElement - ArmRight
    6: [1, 3, 3, 5, 17], // ArmLeft - Element - Element - CornerRight - PoofElement
    7: [1, 3, 3, 5, 17, 11], // ArmLeft - Element - Element - CornerRight - PoofElement - ArmRight
    8: [1, 3, 3, 5, 17, 13, 11], // ArmLeft - Element - Element - CornerRight - PoofElement - Element - ArmRight
    9: [1, 3, 5, 13, 11], // ArmLeft - Element - CornerRight - Element - ArmRight
    10: [1, 3, 5, 13, 17, 11], // ArmLeft - Element - CornerRight - Element - PoofElement - ArmRight
    11: [1, 3, 5, 17], // ArmLeft - Element - CornerRight - PoofElement
    12: [1, 3, 5, 17, 11], // ArmLeft - Element - CornerRight - PoofElement - ArmRight
    13: [1, 3, 5, 17, 13, 11], // ArmLeft - Element - CornerRight - PoofElement - Element - ArmRight
    14: [1, 3, 7], // ArmLeft - Element - ArmLongueRight
    15: [1, 3, 8], // ArmLeft - Element - PoofElement
    16: [6, 3, 2], // ArmLongueLeft - Element - ArmRight
    17: [6, 3, 8], // ArmLongueLeft - Element - PoofElement
    18: [6, 3, 3, 8], // ArmLongueLeft - Element - Element - PoofElement
    19: [8, 3, 7], // PoofElement - Element - ArmLongueRight
    20: [8, 3, 3, 7], // PoofElement - Element - Element - ArmLongueRight
    21: [16, 4, 3, 2], // PoofElement - CornerLeft - Element - ArmRight
    22: [1, 3, 3, 5, 13, 15],
    23: [1, 3, 3, 7],
    24: [1, 3, 3, 5, 13, 13, 15],
    25: [1, 3, 3, 5, 17, 13, 15],
    26: [1, 3, 3, 5, 13, 17, 15],
};

const WARNING_TOO_SMALL = 'Het is niet mogelijk om de bank kleiner te maken dan deze opstelling';
const WARNING_OUTSIDES = 'U kunt alleen de buitenste elementen van de bank verwijderen';
const WARNING_POOF = 'U kunt niet alleen een losse poef overhouden';

function Configurator() {
    const [fields, updateFields] = React.useState({});
    const [forgot, setForgot] = React.useState();
    const [lightBoxOpen, openLightBox] = React.useState(false);
    const [imageToHighlight, setImageToHighlight] = React.useState();
    const [_, setContacted] = React.useState(false);
    const [color, setColor] = React.useState(colorMap[0]);
    const [summaryImage, setSummaryImage] = React.useState(imageMap['1,3,2']);
    const params = useParams();
    const setField = (key, value) => {
        updateFields({...fields, [key]: value});
    };


    const getPartner = (options) => {
        const partner = partners[params.partner];
        if (!partner) {
            window.location.href = window.location.origin;
        }
        if (options && options.displayName) {
            return <span>{partner.displayName}</span>;
        } else if (options && options.displayLogo) {
            return <img src={partner.logoUrl} />;
        } else if (options && options.partner) {
            return partner;
        }
        return <span>{partner.displayName}</span>;
    };

    const [items2, setItems2] = React.useState([
        {
            index: 0,
            id: 3,
            name: 'Element',
            component: Element,
            startPosition: [0, 0],
            rotation: 0,
            hor: 2,
            ver: 2,
            length: 92,
            width: 117,
            height: 84,
        },
    ]);
    const [dimensions, setDimensions] = React.useState({
        length: 0,
        width: 0,
        height: 0,
    });
    const updateDimensions = (length, width, height) => {
        setDimensions({
            length,
            width,
            height,
        });
    };

    const generateIndex = () => {
        for (let i = 0; i < MAX_ELEMENTS; i++) {
            const match = items2.filter(item => item.index === i);
            if (!match || match.length === 0)
                return i;
        }
        return null;
    };

    const createComponent = (element, index) => {
        return React.createElement(element.component, {
            key: index,
            index: index,
            position: [0, 0],
            rotation: element.rotation,
            hor: element.hor,
            ver: element.ver,
        });
    };

    const getItem = (index, element) => {
        let item;
        if (element) {
            item = {
                index: index,
                id: element.id,
                name: element.name,
                component: element.component,
                startPosition: element.startPosition,
                rotation: element.rotation,
                hor: element.hor,
                ver: element.ver,
                length: element.length,
                width: element.width,
                height: element.height,
                parent: element.parent,
            };
        }
        return item;
    };

    /** Returns an array containing all element ids **/
    const getElementIDArray = (elements) => {
        const ids = [];
        if (elements.length >= 2)
            elements.sort((a, b) => a.index > b.index ? 1 : -1);
        elements.forEach((element) => {
            ids.push(element.id);
        });
        return ids;
    };

    /** Returns the index of the poof element, or -1 if there is no poof **/
    const getPoofIndex = (setup) => {
        const poof = POSSIBLE_ELEMENTS.filter((element) => element.component === Poof)[0];
        const additional = setup.findIndex((id) => { return ADDITIONAL_ELEMENTS.includes(id); });
        let poofIndex = -1;
        if (additional >= 0 && poof.id === setup[additional])
            poofIndex = additional;
        return poofIndex;
    };

    /** Returns the current setup array (without additional elements) **/
    const getCurrentConfiguration = () => {
        const ids = getElementIDArray(items2);
        const additional = ids.findIndex((id) => { return ADDITIONAL_ELEMENTS.includes(id); });
        if (additional >= 0)
            ids.splice(additional, 1);
        return ids;
    };

    /** Checks if the current setup matches any of the predefined couch setups **/
    const getCurrentConfigurationId = () => {
        const ids = getCurrentConfiguration();
        let curConfig;
        Object.keys(POSSIBLE_CONFIGURATIONS).map((key) => {
            const configg = POSSIBLE_CONFIGURATIONS[key];
            if (JSON.stringify(configg) === JSON.stringify(ids))
                curConfig = key;
        });
        return curConfig;
    };

    /** Find all the configurations that are stil possible in the current setup **/
    const getAvailableConfigurations = (curConfig) => {
        const current = curConfig ? POSSIBLE_CONFIGURATIONS[curConfig] : getElementIDArray(items2);
        const additional = current.findIndex((id) => { return ADDITIONAL_ELEMENTS.includes(id); });
        if (additional >= 0)
            current.splice(additional, 1);
        const availableConfig = [];
        Object.keys(POSSIBLE_CONFIGURATIONS).map((key) => {
            const configg = POSSIBLE_CONFIGURATIONS[key];
            if (JSON.stringify(configg) !== JSON.stringify(current)) {
                let match = false;
                const indexes = configg.map((item, i) => item === current[0] ? i : undefined).filter(x => x !== undefined);
                indexes.forEach((idx) => {
                    match = !match ? doSetupsMatch(current, configg, idx) : match;
                });
                if (match) availableConfig.push(key);
            }
        });
        return availableConfig;
    };

    /** Find all the elements that can be added to the current setup **/
    const getAvailableElements = (availableConfig) => {
        const current = getElementIDArray(items2);
        const poofIndex = getPoofIndex(current);
        if (poofIndex >= 0)
            current.splice(poofIndex, 1);
        const elements = [];
        let options = [];
        availableConfig.forEach((id) => {
            const configg = POSSIBLE_CONFIGURATIONS[id];
            const index = configg.findIndex((element) => { return element === current[0]; });
            let idx = index >= 0 ? index : 0;
            if (configg[idx - 1])
                options.push(configg[idx - 1]);
            let curIdx = 0;
            for (idx; idx < configg.length; idx++) {
                if (current[curIdx] === configg[idx]) { curIdx++; continue; }
                options.push(configg[idx]);
                break;
            }
        });
        options = [...new Set(options)];
        if (options.length >= 2)
            options.sort((a, b) => a > b ? 1 : -1);
        options.forEach((option) => {
            const match = POSSIBLE_ELEMENTS.filter((element) => element.id === option);
            if (match && match.length > 0)
                elements.push(match[0]);
        });
        if (poofIndex < 0) {
            const poof = POSSIBLE_ELEMENTS.filter((element) => element.component === Poof)[0];
            elements.push(poof);
        }
        return elements;
    };

    /** Returns an array of items with corresponding price **/
    const getOverview = () => {
        const overview = [];
        items2.map((item, i) => {
            const props = createComponent(item, i).props;
            let id = item.id;
            if (item.parent) id = item.parent;
            overview.push({
                id: id,
                name: props.name,
                price: pricingMap[id][color.name],
            });
        });
        return overview;
    };

    /** Determine the start position of the additional element by examining the current setup **/
    const getAdditionalStartPosition = (items) => {
        let middle = 0;
        let left = 0;
        let right = 0;
        items.forEach((item) => {
            if (LEFT_ELEMENTS.indexOf(item.id) >= 0) left++;
            else if (LEFT_CORNER_ELEMENTS.indexOf(item.id) >= 0) left++;
            else if (RIGHT_ELEMENTS.indexOf(item.id) >= 0) right++;
            else if (RIGHT_CORNER_ELEMENTS.indexOf(item.id) >= 0) right++;
            else if (TOP_ELEMENTS.indexOf(item.id) >= 0) middle += item.hor;
        });
        const additional = Math.floor(middle / 3);
        let xCoord = additional;
        if (right > 1)
            xCoord = middle > 2 || left > 0 ? 2 + (additional - 1) : 0;
        else if (left > 1)
            xCoord = 3;
        else if (left > 0 && right > 0)
            xCoord += 2;
        else if (left === 1)
            xCoord = additional + 2;
        return [xCoord, 3];
    };

    const setCouchImage = (items) => {
        const setup = getElementIDArray(items);
        const poofIndex = getPoofIndex(setup);
        if (poofIndex >= 0)
            setup.splice(poofIndex, 1);
        const orderString = JSON.stringify(setup);
        const order = orderString.substring(1, orderString.length - 1);
        const summaryFrontalView = poofIndex >= 0 ? imageMap['poof'] : imageMap[order] || imageMap['no'];
        setSummaryImage(summaryFrontalView);
    };

    const calculateTotalPrice = () => {
        let totalPrice = 0;
        items2.forEach((item) => {
            let id = item.id;
            if (item.parent) id = item.parent;
            totalPrice += parseFloat(pricingMap[id][color.name]);
        });
        return totalPrice;
    };

    /** Compares two setups, starting at a specified index for the comparison item **/
    const doSetupsMatch = (actualSetup, comparisonSetup, idx) => {
        let match = true;
        actualSetup.forEach((element) => {
            if (ADDITIONAL_ELEMENTS.includes(element)) { idx++; return; }
            if (element === comparisonSetup[idx]) { idx++; return; }
            match = false;
        });
        return match;
    };

    /** Checks whether this element can be removed **/
    const isRemovalAllowed = (element) => {
        const poof = POSSIBLE_ELEMENTS.filter((e) => e.component === Poof)[0];
        const hasPoof = items2.filter((item) => item.id === poof.id);
        if (hasPoof.length > 0) {
            if (element.id === poof.id)
                return { remove: true, message: '' };
            if (items2.length === 2 && element.id !== poof.id)
                return { remove: false, message: WARNING_POOF };
            if (element !== items2[1] && element !== items2[items2.length - 1])
                return { remove: false, message: WARNING_OUTSIDES };
        } else {
            if (items2.length <= 1)
                return { remove: false, message: WARNING_TOO_SMALL };
            if (element !== items2[0] && element !== items2[items2.length - 1])
                return { remove: false, message: WARNING_OUTSIDES };
        }
        return { remove: true, message: '' };
    };

    const addElement = async (element) => {
        const items = [...items2];
        let item;
        //Remove additional item(s) as to not move them
        if (items[0].index === -1)
            items.splice(0, 1);
        if (LEFT_CORNER_ELEMENTS.indexOf(element.id) >= 0) {
            element.startPosition = [0, 0];
            item = getItem(0, element);
            //This element needs to go top left, move everything else to the right
            items.forEach((item) => {
                item.index = item.index + 1;
                item.startPosition = [item.startPosition[0] + item.hor, item.startPosition[1]];
            });
        } else if (RIGHT_CORNER_ELEMENTS.indexOf(element.id) >= 0) {
            const lastElement = [...items].slice(-1)[0];
            element.startPosition = [lastElement.startPosition[0] + lastElement.hor, 0];
            item = getItem(generateIndex(), element);
        } else if (TOP_ELEMENTS.indexOf(element.id) >= 0) {
            //Determine if the element should be placed on the left or the right
            let goLeft = false;
            await items.map((i) => {
                if (RIGHT_CORNER_ELEMENTS.indexOf(i.id) >= 0)
                    goLeft = true;
            });
            let index = 0;
            if (goLeft) {
                element.startPosition = [0, 0];
                items.forEach((item) => {
                    item.index = item.index + 1;
                    const newX = item.startPosition[0] + item.hor;
                    const coord = [newX, item.startPosition[1]];
                    item.startPosition = coord;
                });
            } else {
                const lastElement = [...items].slice(-1)[0];
                index = generateIndex();
                element.startPosition = [lastElement.startPosition[0] + lastElement.hor, 0];
            }
            item = getItem(index, element);
        } else if (LEFT_ELEMENTS.indexOf(element.id) >= 0) {
            element.startPosition = [0, items[0].startPosition[1] + items[0].ver];
            item = getItem(0, element);
            items.forEach((item) => {
                item.index = item.index + 1;
            });
        } else if (RIGHT_ELEMENTS.indexOf(element.id) >= 0) {
            const lastElement = [...items].slice(-1)[0];
            element.startPosition = [lastElement.startPosition[0], lastElement.startPosition[1] + lastElement.ver];
            item = getItem(generateIndex(), element);
        } else {
            element.startPosition = getAdditionalStartPosition(items);
            item = getItem(-1, element);
        }
        if (item) {
            const newItems = [...items2];
            newItems.push(item);
            if (newItems.length > 1)
                newItems.sort((a, b) => a.index > b.index ? 1 : -1);
            if (newItems[0].index === -1)
                newItems[0].startPosition = getAdditionalStartPosition(newItems);
            await setItems2(newItems);
            setCouchImage(newItems);
        }
    };

    const removeElement = async (element) => {
        let newItems;
        if (element) {
            const {remove, message} = isRemovalAllowed(element);
            if (remove) {
                newItems = [...items2];
                const match = newItems.filter(item => item.index === element.index);
                if (match && match.length > 0) {
                    const index = newItems.indexOf(match[0]);
                    newItems.splice(index, 1);
                }
                if (element.index === 0) {
                    let idx = 0;
                    newItems.forEach((item) => {
                        if (item.index > 0) {
                            item.index = item.index - 1;
                            if (!LEFT_ELEMENTS.indexOf(element.id) >= 0) {
                                const coord = [idx, item.startPosition[1]];
                                item.startPosition = coord;
                                if (LEFT_CORNER_ELEMENTS.indexOf(item.id) >= 0 ||
                                    TOP_ELEMENTS.indexOf(item.id) >= 0) {
                                    idx += item.hor;
                                }
                            }
                        }
                    });
                }
            } else {
                store.addNotification({
                    title: 'Onjuiste handeling',
                    message: message,
                    type: 'warning',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animated', 'fadeIn'],
                    animationOut: ['animated', 'fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: true,
                    },
                });
                return;
            }
        }
        if (newItems) {
            if (newItems[0].index === -1)
                newItems[0].startPosition = getAdditionalStartPosition(newItems);
            await setItems2(newItems);
            setCouchImage(newItems);
        }
    };

    const createAppointment = () => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const errors = [];
        if (!fields || fields && !fields.name || !fields.email) {
            errors.push('U bent vergeten uw gegevens in te vullen');
        }
        if (!re.test(String(fields.email).toLowerCase())) {
            errors.push('Uw email is ongeldig');
        }
        if (errors.length) {
            return setForgot(errors);
        }
        const skus = items2.map(item => {
            return {sku: skuMap[item.id][color.name].sku};
        });
        // Axios.post(`http://localhost:48081/sofa/sendconfirmpartner`, {...getPartner({partner: true}), ...fields, skus, color}).then(() => setContacted(true));
        Axios.post(`${config.api_base_url}/sofa/sendconfirmpartner`, {...getPartner({partner: true}), ...fields, skus, color}).then(() => setContacted(true));
        swal({
            title: 'Bedankt!',
            text: 'Bekijk uw mail voor meer informatie.',
            icon: 'success',
        });
        updateFields({});

    };

    const openImage = async (image) => {
        await setImageToHighlight(image);
        openLightBox(true);
    };

    const renderFrontalViewImage = () => {
        if (summaryImage && summaryImage.notAvailable) {
            return <span className='placeHolderText'>
            Er is geen vooraanzicht van uw opstelling beschikbaar.
            </span>;
        }
        if (summaryImage) {
            if (summaryImage.mirror) {
                return <img className='sofaImage' style={{transform: 'scaleX(-1)'}} src={summaryImage ? `${config.image_base_url}/original/configurator/${color.name+summaryImage.img}` : null}></img>;
            }
            return <img className='sofaImage' src={summaryImage ? `${config.image_base_url}/original/configurator/${color.name+summaryImage.img}` : null}></img>;
        }
        return <span className='placeHolderText'>
            Uw samengestelde bank verschijnt wanneer u een bank heeft samengesteld.
        </span>;
    };

    const renderWelcome = () => {
        return <div className='swellPopup'>
            <h1>Welkom op de PTMD banken configurator!</h1>
            <hr width='100px' />
            <p>Ontwerp de bank die precies bij jouw stijl en levenssituatie past. Alle mogelijke elementen in alle beschikbare kleuren zijn opgenomen in deze tool. Probeer verschillende opstellingen en krijg meteen een inschatting van de kosten.</p>
            <div className='welcomeImages'>
                {
                    welcomeImages.map(image => <div key={image.image + image.title} className='welcomeImageHolder'>
                        <span>{image.title}</span>
                        <img width='120px' height='120px' src={`${config.image_base_url}/original/configurator/${image.image}.png`}></img>
                        <p>{image.subtext}</p>
                    </div>)
                }
            </div>
        </div>;
    };

    const GoBackButton = () => {
        if (window.history.length) {
            return <span onClick={() => window.history.back()}><i className="fas fa-arrow-left back"></i> Terug</span>;
        }
    };

    const InfoButton = () => {
        return <div onClick={() => openImage('info')}><i className="fas fa-info-circle sofaInfo"></i></div>;
    };
    const Header = () => {
        return <div className='sofaConfigHeader'>
            <div className='configHeader'>
                <div className='configHeader_sec first'>
                    <GoBackButton />
                </div>
                <div className='configHeader_sec middle'>
                    {getPartner()} <span style={{fontSize: '15px'}}>Sofa configurator</span>
                </div>
                <div className='configHeader_sec last'><InfoButton /></div>

            </div>
        </div>;
    };

    React.useEffect(() => swali({content: renderWelcome(), button: 'Aan de slag!', className: 'firstTimeSwell'}), []);
    if (items2.length >= 2)
        items2.sort((a, b) => a.index > b.index ? 1 : -1);
    const currentConfig = getCurrentConfigurationId();
    const availableConfig = getAvailableConfigurations(currentConfig);
    const buttons = getAvailableElements(availableConfig);
    const overview = getOverview();
    const totalPrice = calculateTotalPrice();
    return (
            <>
                <ReactNotification />
                <div className="configuratorPage">
                    <div className='configHolder'>
                        <Header />
                        <div className='configFirst'>
                            <div className='leftMenu'>
                                <div className='configTitleHolder'>
                                    <span className='configTitle'>Onderdelen - Stap 1</span>
                                </div>
                                <div className='configuratorDisplay'>
                                    {
                                        buttons.map((element, index) => {
                                            const props = createComponent(element, index).props;
                                            const rotation = 'rotate(' + element.rotation + 'deg)';
                                            const width = element.hor * 50 + 'px';
                                            let ver = 60;
                                            if (element.hor === 3 || element.ver === 3)
                                                ver += 30;
                                            return <motion.div key={element.name + element.rotation}
                                                className="grid-item"
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                onClick={() => addElement(element)}>
                                                <div className="grid-item-content padding10" style={{width: width}}>
                                                    <span className='blockTitle'>{props.name}</span>
                                                    <div className='blockImgHolder'>
                                                        <img width='60px' height={ver + 'px'} style={{transform: rotation}} src={`${config.image_base_url}/original/configurator/${props.image}`}></img>
                                                    </div>
                                                </div>
                                            </motion.div>;
                                        })
                                    }
                                </div>
                            </div>
                            <div className='summaryDisplay'>
                                <div className='holderSofa'>
                                    <div className='sofaUnderTitles'>
                                        <span className='configTitle'>Bovenaanzicht</span>
                                        <span style={{color: 'grey', fontSize: '0.8em', padding: '10px', textAlign: 'end'}}>
                                            Klik op een onderdeel om te verwijderen.<br />Alleen de buitenste elementen kunnen verwijderd worden.
                                        </span>
                                    </div>
                                    <Grid maxHorCells={10} maxVerCells={8} items={items2} remove={removeElement} dimensions={updateDimensions} />
                                </div>
                                <div className='sofaUnder'>
                                    <div className='sofaUnderTitles'>
                                        <span className='configTitle'>Samengestelde bank - Vooraanzicht</span>
                                    </div>
                                    <div className='displaySofa'>
                                        { renderFrontalViewImage() }
                                        <span style={{color: 'black', fontSize: '0.8em', padding: '10px', textAlign: 'center', fontWeight: 'bold'}}>
                                            { summaryImage && summaryImage.notAcc &&
                                              <span><i style={{marginRight: '10px'}} className="fa fa-exclamation-triangle" aria-hidden="true"></i>Deze foto kan afwijken van de werkelijke opstelling</span>
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='configSecond'>
                            <div className='leftMenu'>
                                <div className='configTitleHolder'>
                                    <span className='configTitle'>Stoffen - Stap 2</span>
                                    <InfoButton />
                                </div>
                                <div className='colorHolder'>
                                    <div className='selectedColorHolder'>
                                        <span style={{fontSize: '0.8em'}}>Geselecteerd</span>
                                        <hr width='100%'/>
                                        <span className='bigger'>{color.label}</span>
                                        <div className='blockImgHolderSelected'>
                                            <img width='90px' height='90px' src={`${config.image_base_url}/original/configurator/${color.img}`}></img>
                                        </div>
                                        <button className='colorDetailsButton' onClick={() => openImage(color.info)}>Stof details</button>
                                    </div>
                                    <div className='configuratorDisplay'>
                                        {
                                            colorMap.map((color) =>
                                                <motion.div key={color.name}
                                                    className="grid-item"
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    onClick={()=> setColor(color)}>
                                                    <div className="grid-item-content">
                                                        <span className='blockTitle'>{color.label}</span>
                                                        <div className='blockImgHolder'>
                                                            <img width='50px' height='50px' src={`${config.image_base_url}/original/configurator/${color.img}`}></img>
                                                        </div>
                                                        <span className='blockTitle'>{color.title}</span>
                                                    </div>
                                                </motion.div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='summaryDisplay'>
                                <div className='sofaUnderTitles'>
                                    <span className='configTitle'>Sfeerbeelden</span>
                                    <span style={{color: 'grey', fontSize: '0.8em', padding: '10px', textAlign: 'end'}}>
                                     Deze afbeeldingen kunnen afwijken van het daadwerkelijke samengestelde product.
                                    </span>
                                </div>
                                <div className='displaySofa'>
                                    {
                                        color.sfeerImgAmount ?
                                            [...color.sfeerImgAmount.map((sfeerImg, i) => {
                                                return <img key={'img' + i}
                                                    className={`sofaImage ${sfeerImg.long && 'longerImage'}`}
                                                    src={`${config.image_base_url}/original/configurator/${color.sfeerFolder}_sfeer_${sfeerImg.img}.jpg`}
                                                    onClick={() => openImage(`${color.sfeerFolder}_sfeer_${sfeerImg.img}`)}>
                                                </img>;
                                            }), <img key={'img'} className={'sofaImage'} onClick={() => openImage('slice')} src={`${config.image_base_url}/original/configurator/slice.jpg`}></img>]
                                            :
                                            <span className='placeHolderText'>
                                            Uw sfeerbeelden verschijnen wanneer u een bank heeft samengesteld.
                                            </span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='configThird'>
                            <div className='rightMenu'>
                                <div className='configTitleHolder'>
                                    <span className='configTitle'>Overzicht - Stap 3</span>
                                    <InfoButton />
                                </div>
                                <div className='holder'>
                                    {
                                        overview.map((item, i) => {
                                            return <div key={item.name + i} className='summaryItemsHolder'>
                                                <span >{item.name}</span>
                                                <span className='rightc'>
                                                    { pricingMap[item.id][color.name] },-
                                                </span>
                                            </div>;
                                        })
                                    }
                                    {/*<div className='summaryItemAmountsHolder'>
                                {
                                    overview.map((item, i) => {
                                        return <span key={item.id + i} className='rightc'>
                                            { pricingMap[item.id][color.name] },-
                                        </span>
                                    })
                                }
                                </div>*/}
                                </div>
                                <div className='totalPriceHolder'>
                                    <span className='configTitle'>Stof:</span>
                                    <span className='configTitle centerc'>{color.label} <div className='miniLabel'><img width='30px' height='30px' src={`${config.image_base_url}/original/configurator/${color.img}`}></img></div></span>
                                </div>
                                <div className='totalPriceHolder'>
                                    <span className='configTitle'>Afmetingen:</span>
                                    <span className='configTitle dimensions'>{ `${dimensions.length}cm L x ${dimensions.width}cm B x ${dimensions.height}cm H` }</span>
                                </div>
                                <hr width='100%' />
                                <div className='totalPriceHolder'>
                                    <span className='configTitle'>Totaal:</span>
                                    <span className='configTitle rightc'>{totalPrice},-</span>
                                </div>
                                <div className='uwGegevens'>
                                    <span className='configTitle'>Uw gegevens</span>
                                    <div className='inputs'>
                                        <div className='gegevensValues'>
                                            <div>
                                                <TextField onChange={e => setField('name', e.target.value)} value={fields.name || ''} id="standard-basic" label="Volledige naam *" inputProps={{style: {paddingLeft: '5px'}}} />
                                            </div>
                                            <div>
                                                <TextField onChange={e => setField('email', e.target.value)} value={fields.email || ''} id="standard-basic" label="Email *" inputProps={{style: {paddingLeft: '5px'}}} />
                                            </div>
                                            <div>
                                                <TextField onChange={e => setField('email2', e.target.value)} value={fields.email2 || ''} id="standard-basic" label="Tweede email" inputProps={{style: {paddingLeft: '5px'}}} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='sofaValidation'>
                                    {
                                        forgot && forgot.map(field => {
                                            return <span key={field}> - {field}</span>;
                                        })
                                    }
                                </div>
                                <motion.div className='confirmSofaButton' whileHover={{ scale: 1.1, background: 'grey' }} whileTap={{ scale: 0.9 }} onClick={() => createAppointment()}>
                                    Confirmeren
                                </motion.div>
                            </div>
                        </div>
                    </div>
                    {
                        lightBoxOpen &&
                        <Lightbox clickOutsideToClose
                            onCloseRequest={() => openLightBox(false)}
                            mainSrc={`${config.image_base_url}/original/configurator/${imageToHighlight}.jpg`}/>
                    }
                </div>
            </>
    );
}

export default Configurator;
