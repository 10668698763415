import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';
import { motion } from 'framer-motion';

const variants = {
    visible: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0 },
};

const NAME = 'Hoek rechts';
const IMAGE = 'A.png';

class CornerRight extends React.Component {

    static get propTypes() {
        return {
            index: PropTypes.number.isRequired,
            name: PropTypes.string,
            image: PropTypes.string,
            position: PropTypes.array.isRequired,
            rotation: PropTypes.number.isRequired,
            hor: PropTypes.number.isRequired,
            ver: PropTypes.number.isRequired,
        };
    }

    static get defaultProps() {
        return {
            name: NAME,
            image: IMAGE,
        };
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const key = (this.props.index + '-' + this.props.name).replace(/\s/g, '');
        const hor = config.grid_cell_size * this.props.hor;
        const ver = config.grid_cell_size * this.props.ver;
        const rotation = 'rotate(' + this.props.rotation + 'deg)';
        const left = this.props.position[0] * config.grid_cell_size + 'px';
        const top = this.props.position[1] * config.grid_cell_size + 'px';
        return (
            <motion.div
                key={key}
                className='element no-padding'
                style={{top: top, left: left}}
                width={hor + 'px'}
                height={ver + 'px'}
                initial='hidden'
                animate='visible'
                variants={variants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}>
                <div className='grid-item-content2' onClick={() => this.props.removeElement(this.props.index)}>
                    <img width={hor + 'px'} height={ver + 'px'} style={{transform: rotation}} src={`${config.image_base_url}/original/configurator/${this.props.image}`}></img>
                </div>
            </motion.div>
        );
    }

}

export default CornerRight;
