export default {
    '1' : {
        'khaki': {
            sku: '687471',
        },
        'taupe': {
            sku: '687470',
        },
        'hunter': {
            sku: '687472',
        },
        'cognac': {
            sku: '687474',
        },
        'black': {
            sku: '687473',
        },
        'rust': {
            sku: '696549',
        },
        'anthrachite': {
            sku: '694960',
        },
        'petrol': {
            sku: '694959',
        },
    },
    '2' : {
        'khaki': {
            sku: '687466',
        },
        'taupe': {
            sku: '687465',
        },
        'hunter': {
            sku: '687467',
        },
        'cognac': {
            sku: '687469',
        },
        'black': {
            sku: '687468',
        },
        'rust': {
            sku: '696548',
        },
        'anthrachite': {
            sku: '694958',
        },
        'petrol': {
            sku: '694957',
        },
    },
    '3' : {
        'khaki': {
            sku: '687476',
        },
        'taupe': {
            sku: '687475',
        },
        'hunter': {
            sku: '687477',
        },
        'cognac': {
            sku: '687479',
        },
        'black': {
            sku: '687478',
        },
        'rust': {
            sku: '696550',
        },
        'anthrachite': {
            sku: '694962',
        },
        'petrol': {
            sku: '694961',
        },
    },
    '4' : {
        'khaki': {
            sku: '687496',
        },
        'taupe': {
            sku: '687495',
        },
        'hunter': {
            sku: '687497',
        },
        'cognac': {
            sku: '687499',
        },
        'black': {
            sku: '687498',
        },
        'rust': {
            sku: '696554',
        },
        'anthrachite': {
            sku: '694970',
        },
        'petrol': {
            sku: '694969',
        },
    },
    '5' : {
        'khaki': {
            sku: '687496',
        },
        'taupe': {
            sku: '687495',
        },
        'hunter': {
            sku: '687497',
        },
        'cognac': {
            sku: '687499',
        },
        'black': {
            sku: '687498',
        },
        'rust': {
            sku: '696554',
        },
        'anthrachite': {
            sku: '694970',
        },
        'petrol': {
            sku: '694969',
        },
    },
    '6' : {
        'khaki': {
            sku: '687496',
        },
        'taupe': {
            sku: '687495',
        },
        'hunter': {
            sku: '687497',
        },
        'cognac': {
            sku: '687499',
        },
        'black': {
            sku: '687498',
        },
        'rust': {
            sku: '696554',
        },
        'anthrachite': {
            sku: '694970',
        },
        'petrol': {
            sku: '694969',
        },
    },
    '7' : {
        'khaki': {
            sku: '687481',
        },
        'taupe': {
            sku: '687480',
        },
        'hunter': {
            sku: '687482',
        },
        'cognac': {
            sku: '687484',
        },
        'black': {
            sku: '687483',
        },
        'rust': {
            sku: '696551',
        },
        'anthrachite': {
            sku: '694964',
        },
        'petrol': {
            sku: '694963',
        },
    },
    '8' : {
        'khaki': {
            sku: '687491',
        },
        'taupe': {
            sku: '687490',
        },
        'hunter': {
            sku: '687492',
        },
        'cognac': {
            sku: '687494',
        },
        'black': {
            sku: '687493',
        },
        'rust': {
            sku: '696553',
        },
        'anthrachite': {
            sku: '694968',
        },
        'petrol': {
            sku: '694967',
        },
    },
    '9' : {
        'khaki': {
            sku: '687491',
        },
        'taupe': {
            sku: '687490',
        },
        'hunter': {
            sku: '687492',
        },
        'cognac': {
            sku: '687494',
        },
        'black': {
            sku: '687493',
        },
        'rust': {
            sku: '696553',
        },
        'anthrachite': {
            sku: '694968',
        },
        'petrol': {
            sku: '694967',
        },
    },
    '10' : {
        'khaki': {
            sku: '687471',
        },
        'taupe': {
            sku: '687470',
        },
        'hunter': {
            sku: '687472',
        },
        'cognac': {
            sku: '687474',
        },
        'black': {
            sku: '687473',
        },
        'rust': {
            sku: '696549',
        },
        'anthrachite': {
            sku: '694960',
        },
        'petrol': {
            sku: '694959',
        },
    },
    '11' : {
        'khaki': {
            sku: '687466',
        },
        'taupe': {
            sku: '687465',
        },
        'hunter': {
            sku: '687467',
        },
        'cognac': {
            sku: '687469',
        },
        'black': {
            sku: '687468',
        },
        'rust': {
            sku: '696548',
        },
        'anthrachite': {
            sku: '694958',
        },
        'petrol': {
            sku: '694957',
        },
    },
    '12' : {
        'khaki': {
            sku: '687476',
        },
        'taupe': {
            sku: '687475',
        },
        'hunter': {
            sku: '687477',
        },
        'cognac': {
            sku: '687479',
        },
        'black': {
            sku: '687478',
        },
        'rust': {
            sku: '696550',
        },
        'anthrachite': {
            sku: '694962',
        },
        'petrol': {
            sku: '694961',
        },
    },
    '13' : {
        'khaki': {
            sku: '687476',
        },
        'taupe': {
            sku: '687475',
        },
        'hunter': {
            sku: '687477',
        },
        'cognac': {
            sku: '687479',
        },
        'black': {
            sku: '687478',
        },
        'rust': {
            sku: '696550',
        },
        'anthrachite': {
            sku: '694962',
        },
        'petrol': {
            sku: '694961',
        },
    },
    '14' : {
        'khaki': {
            sku: '687486',
        },
        'taupe': {
            sku: '687485',
        },
        'hunter': {
            sku: '687487',
        },
        'cognac': {
            sku: '687489',
        },
        'black': {
            sku: '687488',
        },
        'rust': {
            sku: '696552',
        },
        'anthrachite': {
            sku: '694966',
        },
        'petrol': {
            sku: '694965',
        },
    },
    '15' : {
        'khaki': {
            sku: '687481',
        },
        'taupe': {
            sku: '687480',
        },
        'hunter': {
            sku: '687482',
        },
        'cognac': {
            sku: '687484',
        },
        'black': {
            sku: '687483',
        },
        'rust': {
            sku: '696551',
        },
        'anthrachite': {
            sku: '694964',
        },
        'petrol': {
            sku: '694963',
        },
    },
    '16' : {
        'khaki': {
            sku: '687476',
        },
        'taupe': {
            sku: '687475',
        },
        'hunter': {
            sku: '687477',
        },
        'cognac': {
            sku: '687479',
        },
        'black': {
            sku: '687478',
        },
        'rust': {
            sku: '696550',
        },
        'anthrachite': {
            sku: '694962',
        },
        'petrol': {
            sku: '694961',
        },
    },
    '17' : {
        'khaki': {
            sku: '687476',
        },
        'taupe': {
            sku: '687475',
        },
        'hunter': {
            sku: '687477',
        },
        'cognac': {
            sku: '687479',
        },
        'black': {
            sku: '687478',
        },
        'rust': {
            sku: '696550',
        },
        'anthrachite': {
            sku: '694962',
        },
        'petrol': {
            sku: '694961',
        },
    },
};
