export default [
    {name: 'khaki', info: 'juke', label: 'JUKE KHAKI 51', title: 'Velvet', img: 'khaki.jpg', sfeerFolder: 'khaki', sfeerImgAmount: [{img:'0', long: true}, {img:'1', long: true}, {img:'2', long: true}, {img:'3', long: true}, {img:'4'}, {img:'5'}, {img:'6'}, {img:'7'}]},
    {name: 'taupe', info: 'juke', label: 'JUKE TAUPE 12', title: 'Velvet', img: 'taupe.jpg', sfeerFolder: 'taupe', sfeerImgAmount: []},
    {name: 'hunter', info: 'juke', label: 'JUKE HUNTER 156', title: 'Velvet', img: 'hunter.jpg', sfeerFolder: 'hunter', sfeerImgAmount: []},
    {name: 'cognac', info: 'colorado', label: 'COLORADO COGNAC 3', title: 'Imitatieleer', img: 'cognac.jpg', sfeerFolder: 'cognac', sfeerImgAmount: []},
    {name: 'black', info: 'colorado', label: 'COLORADO BLACK 1', title: 'Imitatieleer', img: 'black.jpg', sfeerFolder: 'black', sfeerImgAmount: [{img:'0'}, {img:'1'}, {img:'2'}, {img:'3'}]},
    {name: 'rust', info: 'adore', label: 'ADORE RUST 28', title: 'Velvet', img: 'rust.jpg', sfeerFolder: 'rust', sfeerImgAmount: [{img:'0'}, {img:'1'}, {img:'2'}, {img:'3'}, {img:'4'}]},
    {name: 'anthrachite', info: 'adore', label: 'ADORE ANTHRACITE 68', title: 'Velvet', img: 'anthrachite.jpg', sfeerFolder: 'hunter', sfeerImgAmount: []},
    {name: 'petrol', info: 'adore', label: 'ADORE PETROL 158', title: 'Velvet', img: 'petrol.jpg', sfeerFolder: 'petrol', sfeerImgAmount: [{img:'0'}, {img:'1'}, {img:'2'}, {img:'3'}]},
];
