/* eslint-disable no-magic-numbers */
import React from 'react';
import PropTypes from 'prop-types';
// import {translate} from 'react-i18next';
import config from '../config/config';
import Empty from '../configurator/components/Empty';

// import '../../css/grid.css';


class Grid extends React.Component {

    static get propTypes() {
        return {
            maxHorCells: PropTypes.number,
            maxVerCells: PropTypes.number,
        };
    }

    static get defaultProps() {
        return {
            maxHorCells: 10,
            maxVerCells: 8,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            grid: [],
            items: [],
            rows: [],
            realLength: 0,
            realWidth: 0,
            realHeight: 0,
            maxHorCells: undefined,
            maxVerCells: undefined,
        };
        this.removeElement = this.removeElement.bind(this);
    }

    initializeGrid(maxHorCells) {
        const grid = [];
        for (let x = 0; x < maxHorCells; x++) {
            grid.push({ x: x, y: [] });
        }
        return grid;
    }

    async componentDidMount() {
        await this.setState({
            maxHorCells: this.props.maxHorCells,
            maxVerCells: this.props.maxVerCells,
            items: [...this.props.items],
            grid: this.initializeGrid(this.props.maxHorCells),
        });
        this.props.items.forEach((item) => {
            this.addElementToGrid(item);
        });
        this.calculateDimensions();
        await this.createConfigurator();
    }

    async componentDidUpdate() {
        const pItems = [...this.props.items];
        const sItems = [...this.state.items];
        if (sItems.length > 0 && sItems.length !== pItems.length) {
            if (pItems.length < sItems.length)
                this.state.grid = this.initializeGrid(this.props.maxHorCells);
            pItems.map((item) => {
                this.addElementToGrid(item);
            });
            await this.setState({
                items: pItems,
            });
            this.calculateDimensions();
            await this.createConfigurator();
        }
    }

    /** Returns the additional element if it exists **/
    getAdditionalElement(items) {
        const match = items.filter(item => item.index < 0);
        if (match && match.length > 0)
            return match[0];
        return null;
    }

    /**
      Elements occupy a certain set of cells, both horizontal and vertical.
      With this method all coordinates that are occupied are returned.
    **/
    getOccupiedCoordinates(start, horCells, verCells) {
        const coordinates = [];
        for (let x = start[0]; x < start[0] + horCells; x++) {
            for (let y = start[1]; y < start[1] + verCells; y++) {
                coordinates.push([x, y]);
            }
        }
        return coordinates;
    }

    /** Sets the state of a grid cell (active means occupied by an element) **/
    toggleGridCell(x, y, active) {
        const yRow = this.state.grid[x].y;
        if (active) {
            if (!yRow.includes(y)) {
                yRow.push(y);
                if (yRow.length >= 2) yRow.sort((a, b) => a > b ? 1 : -1);
            }
        } else {
            const index = yRow.indexOf(y);
            if (index > -1) yRow.splice(index, 1);
        }
    }

    createElement(element) {
        return React.createElement(element.component, {
            key: element.index,
            index: element.index,
            position: element.startPosition,
            rotation: element.rotation,
            hor: element.hor,
            ver: element.ver,
            removeElement: this.removeElement.bind(this),
        });
    }

    /** Sets all grid cells that are occupied by the supplied element to active **/
    addElementToGrid(element) {
        const coordinates = this.getOccupiedCoordinates(element.startPosition, element.hor, element.ver);
        if (coordinates && coordinates.length > 0) {
            coordinates.map((coord) => {
                if (!this.state.grid[coord[0]].y.includes(coord[1]))
                    this.toggleGridCell(coord[0], coord[1], true);
            });
        }
    }

    getElementByIndex(index) {
        const item = this.props.items.find(i => i.index === index);
        if (item) return item;
        return null;
    }

    getElementByStartPosition(startPosition) {
        const item = this.props.items.find(i => JSON.stringify(i.startPosition) === JSON.stringify(startPosition));
        if (item) return item;
        return null;
    }

    async removeElement(index) {
        const element = this.getElementByIndex(index);
        await this.props.remove(element);
    }

    /** Sets all grid cells that are no longer occupied by the supplied element to inactive **/
    removeElementFromGrid(element) {
        const coordinates = this.getOccupiedCoordinates(element.startPosition, element.hor, element.ver);
        if (coordinates && coordinates.length > 0) {
            coordinates.forEach((coord) => {
                this.toggleGridCell(coord[0], coord[1], false);
            });
        }
    }

    /** Calculates the actual length, width and height of the setup **/
    calculateDimensions() {
        let lengthActual = 0;
        let widthActual = 0;
        let heightActual = 0;
        //Don't count the additional element
        const additional = this.getAdditionalElement(this.props.items);
        const coordinates = [];
        if (additional) {
            const coords = this.getOccupiedCoordinates(additional.startPosition, additional.hor, additional.ver);
            coords.forEach((coord) => {
                coordinates.push(JSON.stringify(coord));
            });
        }

        this.state.grid.forEach((xRow) => {
            let rowLength = 0;
            xRow.y.forEach((y) => {
                const coord = [xRow.x, y];
                const element = this.getElementByStartPosition(coord);
                if (element) {
                    //Get length
                    if (!coordinates.includes(JSON.stringify(coord)))
                        rowLength += element.length;
                    //Get width
                    if (y === 0)
                        widthActual += element.width;
                    //Get height
                    if (element.height > heightActual)
                        heightActual = element.height;
                }
            });
            if (rowLength > lengthActual)
                lengthActual = rowLength;
        });
        this.setState({realLength: lengthActual});
        this.setState({realWidth: widthActual});
        this.setState({realHeight: heightActual});
        this.props.dimensions(lengthActual, widthActual, heightActual);
    }

    /**
      Generates the configurator and positions all elements by their respective coordinates.
      Empty space is filled with 'Empty' elements.
    **/
    async createConfigurator() {
        const rows = [];
        for (let x = 0; x < this.state.maxHorCells; x++) {
            const cells = [];
            for (let y = 0; y < this.state.maxVerCells; y++) {
                const match = this.state.grid.filter(cell => cell.x === x && cell.y.includes(y));
                if (match && match.length > 0) {
                    const coord = [x, y];
                    const item = this.props.items.find(it => JSON.stringify(it.startPosition) === JSON.stringify(coord));
                    if (item) cells.push(item);
                } else {
                    const element = {
                        key: x + y,
                        index: x + y,
                        component: Empty,
                        startPosition: [x, y],
                        hor: 1,
                        ver: 1,
                    };
                    cells.push(element);
                }
            }
            rows.push(cells);
        }
        await this.setState({rows});
    }

    /** Calculates the render dimensions of the actual grid itself **/
    calculateRenderDimensions() {
        let widthMaxIndex = 0;
        let height = 0;
        this.state.grid.forEach((gridItem, i) => {
            if (gridItem && gridItem.y.length) {
                widthMaxIndex = i;
                if (gridItem.y.length > height) height = gridItem.y.length;
            }
        });
        const width = widthMaxIndex % 2 === 0 || widthMaxIndex === 0 ? widthMaxIndex : widthMaxIndex + 1;
        return {
            width: `${width * config.grid_cell_size}px`,
            height: `${height * config.grid_cell_size}px`,
        };
    }

    render() {
        const length = this.state.realLength;
        const width = this.state.realWidth;
        const height = this.state.realHeight;
        return (
            <div className='sofaWithDims'>
                <span className='itemDimensions'>
                    { `${length}cm L x ${width}cm B x ${height}cm H` }
                </span>
                <div style={ this.calculateRenderDimensions() }>
                    {
                        this.state.rows.map((row, i) => {
                            return <div key={i} className='grid-row'>{
                                row.map(cel => { return this.createElement(cel); })
                            }</div>;
                        })
                    }
                </div>
            </div>
        );
    }

}

export default Grid;
