import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Configurator from '../../configurator/Configurator';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import Nopartner from '../Nopartner';


const App = () =>
    <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
            <Route exact path='/' component={Nopartner} />
            <Route exact path='/nopartner' component={Nopartner} />
            <Route exact path='/:partner' component={Configurator} />
        </QueryParamProvider>
    </BrowserRouter>
;

export default App;
