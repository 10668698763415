import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import Routing from './containers/app/routing';

const target = document.querySelector('#root');

render(
    <Provider store={store}>
        <Routing />
    </Provider>,
    target
);
