export const liveConfig = {
    'google_api_key': 'AIzaSyAtJVgzJliFPL8CSRSMaYeLE8b9oII7Ieo',
    'api_base_url': 'https://api.ptmd.nl/rest/b2c/v1',
    'image_base_url': 'https://api.ptmd.nl/rest/images/v1',
    'store_locator_base_url': 'https://api.ptmd.nl/rest/b2c/v1/locator',
    'debug-i18n': false,
    'grid_cell_size': 50,
};

export const devConfig = {
    'google_api_key': 'AIzaSyCty7L07IlpeiexO5gIGMRmcei_4ZhZlGM',
    'api_base_url': 'https://staging.api.ptmd.nl/rest/b2c/v1',
    'image_base_url': 'https://staging.api.ptmd.nl/rest/images/v1',
    'store_locator_base_url': 'https://staging.api.ptmd.nl/rest/b2c/v1/locator',
    'debug-i18n': true,
    'google_tag_manager': 'GTM-5GDSWRG',
    'grid_cell_size': 50,
};

const getConfig = () => {
    if (window.location.href.includes('beta') || window.location.href.includes('localhost') ) {
        return devConfig;
    }
    return liveConfig;
};

export default getConfig();
